import React from 'react'
import styled from 'styled-components'
import { truncate } from 'lodash'
import { Link } from 'gatsby'

const generateGridTemplateColumns = cols =>
  new Array(cols).fill('1fr').join(' ')

export const List = styled.div`
  display: grid;
  grid-template-columns: ${({ cols = 3 }) => generateGridTemplateColumns(cols)};
  column-gap: 5.5%;
`

const Tile = styled.div`
  display: flex;
  ${({ direction }) =>
    direction === 'horizontal'
      ? `flex-direction: row; align-items: center;`
      : `flex-direction: column;`}
  margin-bottom: 30px;
  a {
    text-decoration: none;
  }
`

const Title = styled.h4`
  text-decoration: none;
  margin-top: 0px;
  font-size: 16px;
  ${({ direction }) =>
    direction === 'horizontal'
      ? `
        height: 50px;
        margin-bottom: 10px;
      `
      : `
        margin-bottom: 5px;
      `}
  display: flex;
  align-items: center;
`

const Img = styled.img`
  ${({ direction }) =>
    direction === 'horizontal'
      ? `
        width: 100px;
        margin-right: 15px;
      `
      : `
        width: 100%;`}
`

const Description = styled.div`
  font-size: 14px;
`

export const ListItem = ({
  href,
  title,
  img,
  description,
  direction = 'vertical',
}) => (
  <Tile direction={direction}>
    <Link to={href}>
      <Img direction={direction} src={img} alt={title} />
    </Link>
    <div>
      <Link to={href}>
        <Title>{title}</Title>
      </Link>
      <Description>{truncate(description, { length: 150 })}</Description>
    </div>
  </Tile>
)
