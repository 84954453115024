import styled from 'styled-components'

const ListTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-family: 'Bitter', serif;
  font-size: 2.25rem;
  font-weight: 800;
  text-transform: uppercase;
  color: ${p => p.theme.primary};
`

export default ListTitle
