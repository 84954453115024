import React from 'react'
import styled from 'styled-components'
import { truncate } from 'lodash'
import { useMedia } from 'react-media'
import { List as BaseTiledList, ListItem as BaseTiledListItem } from './Tiled'

const StyledBaseTileList = styled(BaseTiledList)`
  ${({ smallMargin }) => smallMargin && 'column-gap: 2.5%;'}
`

const TiledList = ({ items }) => {
  const { medium, small, tiny } = useMedia({
    queries: {
      medium: '(max-width: 900px)',
      small: '(max-width: 750px)',
      tiny: '(max-width: 500px)',
    },
  })

  const numCols = (() => {
    if (tiny) return 1
    if (small) return 2
    if (medium) return 3
    return 5
  })()

  return (
    <StyledBaseTileList smallMargin={!small} cols={numCols}>
      {items.map(({ title, ...other }) => (
        <BaseTiledListItem
          direction={tiny ? 'horizontal' : 'vertical'}
          title={truncate(title, { length: 30 })}
          {...other}
        />
      ))}
    </StyledBaseTileList>
  )
}

export default TiledList
